import store from '@/store';
import {
    mdiAccountCheckOutline,
    mdiAccountOutline,
    mdiAccountPlusOutline,
    mdiAccountRemoveOutline,
    mdiCogOutline,
    mdiDatabaseOutline,
    mdiDnsOutline,
    mdiPencilOutline,
} from '@mdi/js';
import { ref, watch } from '@vue/composition-api';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import en from '@/plugins/i18n/locales/en';

export default function useAllergiesList() {
    const limitationListTable = ref([]);

    const tableColumns = [
        { text: i18n.t('Name'), value: 'name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Description'), value: 'description', class: 'text-uppercase' },
        { text: i18n.t('Users'), value: 'users', class: 'text-uppercase' },
        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const searchQuery = ref('');
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const totalLimitationListTable = ref(0);
    const loading = ref(false);
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });
    const limitationTotalLocal = ref([]);
    const selectedRows = ref([]);
    const oldSearch = ref('');
    const oldUserFilter = ref(' ');

    // fetch data
    const fetchLimitationList = () => {
        let verifyQuery = store.getters['app-limitation/getLimitationQuery'];
        let isDeleteLimitation = store.getters['app-limitation/getIsDeleteLimitation'];
        store
            .dispatch('app-limitation/fetchLimitationList', {})
            .then((response) => {
                if (isDeleteLimitation) {
                    limitationListTable.value = [];
                    limitationListTable.value.push(...response.data.data);
                    store.commit('app-limitation/setIsDeleteLimitation', false);
                } else if (
                    oldSearch.value == verifyQuery.filters.$or[0].name.$containsi
                    // &&
                    // oldUserFilter.value === verifyQuery.filters.users.id.$containsi
                ) {
                    limitationListTable.value = [];
                    limitationListTable.value.push(...response.data.data);
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].name.$containsi;
                    // oldUserFilter.value = verifyQuery.filters.users.id.$containsi;
                    limitationListTable.value = [];
                    limitationListTable.value.push(...response.data.data);
                }
                totalLimitationListTable.value = response.data.meta.pagination.total;

                // limitationTotalLocal.value = limitationTotal;
                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    watch([searchQuery, roleFilter, planFilter, statusFilter, options], () => {
        loading.value = true;
        selectedRows.value = [];
        fetchLimitationList();
    });

    const resolveLimitationPicture = (pictures) => {
        if (_.get(pictures, '[0].formats.thumbnail.url')) return _.get(pictures, '[0].formats.thumbnail.url');

        if (_.get(pictures, 'url')) return _.get(pictures, 'url');
        return null;
    };
    const resolveLimitationPictureMax = (pictures) => {
        if (_.get(pictures, '[0].formats.large.url')) return _.get(pictures, '[0].formats.large.url');

        if (_.get(pictures, '[0].url')) return _.get(pictures, '[0].url');
        return null;
    };
    const resolveLimitationRoleVariant = (role) => {
        if (role === 'subscriber') return 'primary';
        if (role === 'author') return 'warning';
        if (role === 'maintainer') return 'success';
        if (role === 'editor') return 'info';
        if (role === 'admin') return 'error';

        return 'primary';
    };

    const resolveLimitationRoleIcon = (role) => {
        if (role === 'subscriber') return mdiAccountOutline;
        if (role === 'author') return mdiCogOutline;
        if (role === 'maintainer') return mdiDatabaseOutline;
        if (role === 'editor') return mdiPencilOutline;
        if (role === 'admin') return mdiDnsOutline;

        return mdiAccountOutline;
    };

    const resolveAllergiestatusVariant = (status) => {
        if (status === 'pending') return 'warning';
        if (status === 'active') return 'success';
        if (status === 'inactive') return 'secondary';

        return 'primary';
    };

    const resolveLimitationTotalIcon = (total) => {
        if (total === 'Total Allergies') return { icon: mdiAccountOutline, color: 'primary' };
        if (total === 'Paid Allergies') return { icon: mdiAccountPlusOutline, color: 'error' };
        if (total === 'Active Allergies') return { icon: mdiAccountCheckOutline, color: 'success' };
        if (total === 'Pending Allergies') return { icon: mdiAccountRemoveOutline, color: 'warning' };

        return { icon: mdiAccountOutline, color: 'primary' };
    };

    const limitLimitationText = (limitations) => {
        if (limitations.length > 1) {
            return ' + ' + (limitations.length - 1);
        }
    };

    return {
        limitationListTable,
        tableColumns,
        searchQuery,
        roleFilter,
        planFilter,
        statusFilter,
        totalLimitationListTable,
        loading,
        options,
        limitationTotalLocal,
        selectedRows,
        fetchLimitationList,
        resolveLimitationPicture,
        resolveLimitationPictureMax,
        resolveLimitationRoleVariant,
        resolveLimitationRoleIcon,
        resolveAllergiestatusVariant,
        limitLimitationText,
    };
}
