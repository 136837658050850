<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto">
                    <v-container>
                        <v-tabs center-active>
                            <v-tab>{{ $t('LimitationProfile') }}</v-tab>
                            <v-tab-item>
                                <v-row class="mt-10">
                                    <v-col
                                        class="form-pic justify-center d-flex d-xs-flex d-sm-flex d-md-none"
                                        cols="12"
                                        md="3">
                                        <v-avatar
                                            :color="form.pictures ? '' : 'primary'"
                                            :class="form.pictures ? '' : 'v-avatar-light-bg primary--text'"
                                            size="200">
                                            <v-img v-if="getPic" :src="getPic"></v-img>
                                            <span v-else class="display-1 font-weight-medium">{{
                                                avatarText(form.name)
                                            }}</span>
                                            <v-file-input
                                                name="photo"
                                                v-show="!isDisabled"
                                                @change="onFileInput"
                                                v-model="file"
                                                class="file"
                                                hide-input></v-file-input>
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <ValidationProvider
                                                    :name="$t('Form.Name')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        v-model="form.name"
                                                        :disabled="isDisabled"
                                                        :label="$t('Form.Name')"
                                                        required
                                                        filled
                                                        hide-details="auto"
                                                        name="name"
                                                        shaped></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <ValidationProvider
                                                    :name="$t('Description')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-textarea
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        filled
                                                        shaped
                                                        v-model="form.description"
                                                        :label="$t('Description')"
                                                        name="tel"
                                                        hide-details="auto"
                                                        required></v-textarea>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="$router.go(-1)" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!addLimitation">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('LimitationProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('LimitationProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddLimitation') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddLimitation') }}
                        </p>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <div v-if="!addLimitation">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>
                        <v-btn v-show="isDisabled" @click="editInformation" class="mx-3" color="grey-light">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.SureDeleteLimitation') }}
                                            <strong class="black--text">{{ form.name }}</strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn @click="deleteLimitation" color="error" class="px-5">{{
                                            $t('Buttons.Delete')
                                        }}</v-btn>
                                        <v-btn text @click="dialog.value = false">{{ $t('Buttons.Cancel') }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <!-- ALERTAS DE SUCESSO E ERRO A ELIMINAR ALERGIAS -->
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddLimitationSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="updateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateLimitationSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isDeleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteLimitationSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>
<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
    } from '@mdi/js';
    import useLimitationList from '../limitation-list/useLimitationList';
    import { avatarText } from '@core/utils/filter';
    import _ from 'lodash';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    const initialState = () => ({
        isLoading: false,
        form: {
            name: '',
            description: '',
            limitation: null,
        },

        isLoadingTable: true,
    });

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,
                nameRules: [
                    (v) => !!v || 'Name is required',
                    // (v) => v.length <= 10 || 'Name must be less than 10 characters',
                ],
                //   costRules: [(v) => !!v || 'Cost is required', (v) => (v && v <= 0) || 'Não pode ser menor que 0'],

                users: [],
                selectedUser: null,
                addLimitation: false,
                dialog: false,
                createSuccess: false,
                updateSuccess: false,
                isDeleteSuccess: false,
                isError: false,
                selectedLimitationType: null,
            };
        },
        async created() {
            if (this.$route.params.id != 'add') {
                this.addLimitation = false;
                this.isDisabled = true;
                await this.getLimitation();
            } else {
                this.addLimitation = true;
                this.isDisabled = false;
            }
            await this.getUsers();
        },

        setup() {
            const { resolveLimitationPictureMax, limitLimitationText } = useLimitationList();

            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,
                resolveLimitationPictureMax,
                avatarText,
                limitLimitationText,
            };
        },
        computed: {
            getPic() {
                return this.resolveLimitationPictureMax(this.form.pictures);
            },
        },
        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },

            //get limitation info
            getLimitation() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-limitation/fetchLimitation', this.$route.params.id)
                    .then((response) => {
                        this.form = response.data.data;

                        this.selectedLimitationType = this.form.type;
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            async onSubmit() {
                if (this.addLimitation) {
                    await this.onAddLimitation();
                } else await this.onUpdateLimitation();

                setTimeout(() => {
                    this.$router.push({ name: 'LimitationList' });
                }, 1500);
            },

            //add limitation
            async onAddLimitation() {
                this.isLoading = true;

                let limitationData = _.assign({}, this.form); // Vai pegar todos os dados do formulário e jogar no objeto limitationData (colonar objeto) é o que faz a função assing do lodash

                await this.$store
                    .dispatch('app-limitation/addLimitation', limitationData)
                    .then(async (response) => {
                        this.createSuccess = true;
                    })

                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            //update limitation
            async onUpdateLimitation() {
                this.isLoading = true;

                let limitationData = _.assign({}, this.form);

                //update limitation
                await this.$store
                    .dispatch('app-limitation/updateLimitation', limitationData)

                    .then(async (response) => {
                        this.updateSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            deleteLimitation() {
                this.$store
                    .dispatch('app-limitation/deleteLimitation', this.$route.params.id)
                    .then((response) => {
                        this.isDeleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'LimitationList' });
                        }, 1500);
                    })
                    .catch((error) => {
                        this.isError = true;
                    });
            },
        },
    };
</script>
<style lang="scss" scoped>
    .is-icon {
        background-color: #0000ff;
        border-radius: 10px;
    }

    .v-avatar {
        position: relative;
        .file {
            background-color: #0000ff;
            border-radius: 10px;
            padding: 0.3rem;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(0%, 0%);
        }
    }
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
